$primary-color: #0EA47A;
@mixin responsive($breakpoint){
    @if $breakpoint == Exsmall{
        @media only screen and (max-width:474px){
            @content
        };
    }
    @if $breakpoint == phone{
        @media only screen and (max-width:768px){
            @content
        };
    }
    @if $breakpoint == tab-port{
        @media only screen and (max-width:992px){
            @content
        };
    }
    @if $breakpoint == devicemax1052{
        @media only screen and (max-width:1052px){
            @content
        };
    }
    @if $breakpoint == device1200{
        @media only screen and (min-width:1200px){
            @content
        };
    }
    @if $breakpoint == device1200max{
        @media only screen and (max-width:1200px){
            @content
        };
    }
    @if $breakpoint == device1300max{
        @media only screen and (max-width:1300px){
            @content
        };
    }
    @if $breakpoint == tab-land{
        @media only screen and (max-width:1400px){
            @content
        };
    }
    @if $breakpoint == big-desktop{
        @media only screen and (min-width:1600px){
            @content
        };
    }
}