@import 'variables.scss';

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
div,
body,
ul,
li {
  font-family: "proxima-nova", sans-serif !important;
}

.primary-bg {
  background: $primary-color;
}

.secondary-btn {
  background-color: white;
  color: $primary-color;
  font-weight: bold;
  border: none;
  opacity: .9;

  &:hover {
    background-color: white;
    color: $primary-color;
    opacity: 1;
  }

}

.banner {
  padding: 80px 0 30px;

  @include responsive(phone) {
    padding: 60px 0 30px;
  }

  .banne_left {
    padding-left: 40px;

    @include responsive(devicemax1052) {
      padding-left: 40px;

    }

    @include responsive(tab-port) {
      padding-left: 0;
      text-align: center;
    }

    h1 {
      font-size: 40px;
      color: #0EA47A;
      font-weight: bold;


      @include responsive(device1300max) {
        font-size: 38px;
      }

      @include responsive(tab-port) {
        font-size: 40px;
        max-width: unset;
      }

      @media screen and (max-width: 600px) {
        font-size: 2rem;

      }
      @media screen and (max-width: 600px) {
        font-size: 2rem;

      }
      @media screen and (max-width: 435px) {
        font-size: 1.6rem;

      }
      @media screen and (max-width: 352px) {
        font-size: 1.5rem;

      }
      @media screen and (max-width: 335px) {
        font-size: 1.4rem;

      }
    }

  }
}

p {
  color: #13243C;
  font-size: 18px;

  @include responsive(big-desktop) {
    font-size: 18px;
    line-height: 22px;
  }

  @include responsive(device1200) {
    font-size: 18px;
    line-height: 22px;
  }

  @include responsive(tab-port) {
    font-size: 16px;
  }

  @include responsive(tab-port) {
    font-size: 16px;
    line-height: 19px;
  }
}

.register {
  background: $primary-color;

  h2 {
    font-size: 33px;
    color: white;
    font-weight: bold;
    margin-bottom: 12px;

    @include responsive(tab-port) {
      font-size: 32px;
    }
  }

  p {
    color: white;
  }
}


.hed-sec {
  padding-bottom: 15px;



  h2 {
    color: $primary-color;
    font-size: 36px;
    font-weight: bold;

    @include responsive(tab-port) {
      font-size: 32px;
    }
  }

  p {
    max-width: 750px;
    margin: 0 auto;

    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #13243C;
  }
}

.light-bgcolor {
  background: #F6F6F6;
}

.inner-text {
  h3 {
    font: normal normal normal 29px/29px Proxima Nova;
    margin-bottom: 15px;
    color: #0EA47A;
    font-weight: bold;

    @include responsive(tab-port) {
      font-size: 25px;
    }
  }

  p {
    // font-size: 1rem;
    color: #13243C;
    max-width: 340px;
    margin: 0 auto;
  }
}

.inner-text-2 {
  p {
    max-width: 500px;
    margin: 0 auto;
  }
}

.primary-text {
  color: $primary-color;
  font-size: 2.8rem;
  font-weight: bold;
}

.accordion.faqAcc {
  padding: 80px 0 !important;

  .accordion-header {
    button {
      font-weight: 600;
      padding-right: 30px;
    }
  }
}

.accordion-item {
  padding-bottom: 10px;
  border-bottom: 1px solid #CBCBCB !important;
}

.faqsSec {
  opacity: 1;

  & .accordion-button {
    font-size: 22px;

    @include responsive(phone) {
      font-size: 16px;
    }

    &::before {
      content: url('../../public/assets/images/plus.svg');
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
    }
  }

  & .accordion-button:not(.collapsed) {
    font-size: 24px;
    letter-spacing: 0px;
    color: #110403;
    background: none;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;

    &::before {
      content: url('../../public/assets/images/minus.svg');
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
    }
  }

  & .accordion-body {
    padding: 1rem 1.25rem;
    font-size: 22px;
    letter-spacing: 0px;
    color: #474747;
    opacity: 0.91;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: left;

    @include responsive(phone) {
      font-size: 16px;
    }
  }

  & .accordion-item {
    background-color: transparent;
    border: none;
  }

  & .accordion-button {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    padding: 15px 0 10px;
  }

  & .accordion-button::after {
    display: none;
  }

  & .accordion-button:focus {
    box-shadow: none;
  }
}

.pt60 {
  padding-top: 60px;
}

.pb60 {
  padding-bottom: 60px;
}

.secHd {
  text-align: center;

  & span {
    font-size: 22px;
    letter-spacing: 0px;
    color: #110403;
    text-transform: uppercase;
    opacity: 1;

    @include responsive(phone) {
      font-size: 16px;
    }
  }

  & h2 {
    font-size: 38px;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;

    @include responsive(phone) {
      font-size: 32px;
    }
  }
}

.contact-cta {
  padding-top: 20px;

  p {
    font-size: 20px;
    color: #13243C;
    line-height: 28px;
  }

  button {
    padding: 10px 40px;
    border-radius: 4px;
    background-color: #0EA47A;
    color: #fff;
    border: none;
  }
}

// sideBar
.sideMenu {
  display: flex;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 99999;
  right: 0;
  box-shadow: 0px 8px 10px rgba(74, 73, 88, 0.14), 0px 3px 14px rgba(74, 73, 88, 0.12), 0px 4px 5px rgba(74, 73, 88, 0.2);

  @include responsive(tab-port-min) {
    display: none !important;
  }
}

.sidebar {
  width: 300px;
  flex-shrink: 0;
  background-color: #fff;
  height: 100%;
  overflow: auto;
  padding: 5px;

  .sidebar-header {
    display: flex;
    justify-content: flex-end;
    padding: 15px 10px;
    border-bottom: 0;
    align-items: center;
    margin-bottom: 15px;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      display: flex;

      &.close {
        cursor: pointer;
      }
    }
  }
}

.sidebar-item {
  padding: 5px 0 5px;
  display: block;
  transition: background-color .15s;
  cursor: pointer;
  color: #0EA47A;
  font-weight: 800 !important;
}

.sidebar_wrap {
  &>.sidebar-item {
    border-bottom: 1px solid #e9e9ef;
  }
}

.sidebar-item:hover {
  background-color: rgba(255, 255, 255, .1);
}

.sidebar-title {
  display: flex;
  font-size: 22px;
  justify-content: space-between;
  padding: 5px 0;
  font-weight: 400;
}

.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}

.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform .3s;

  &::before {
    transform: rotate(-90deg);
  }
}

.sidebar-item.open>.sidebar-title .toggle-btn {
  transform: rotate(90deg);
}

.sidebar-content {
  height: 0;
  overflow: hidden;
  padding: 0 15px;
  position: relative;
  text-align: left;
}

.sidebar-item.open>.sidebar-content {
  height: auto;
  padding-top: 10px;
}

.sidebar-item {
  &.open {
    >.sidebar-title {
      padding: 5px 0;
      background: transparent;
      border-radius: 4px;
      color: #0EA47A;
    }
  }
}

.sidebar_wrap {
  padding: 0 10px;
}

.sidebar-item.plain {
  color: #000;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.sidebar-item.plain:hover {
  text-decoration: underline;
}

.sidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
}

.hambergerMenu {
  position: absolute;
  width: 25px;
  height: 18px;
  display: flex;
  gap: 6px;
  flex-direction: column;
  justify-content: space-between;
  right: 50px;
  cursor: pointer;

  @include responsive(tab-port) {
    right: 0;
  }

  span {
    height: 3px;
    width: 100%;
    background-color: #fff;

    &:nth-child(2) {
      width: 80%;
      margin: auto;
    }
  }

  &.scrollHamberger {
    position: inherit;

    span {
      background-color: #0EA47A;
    }

    &.showOnMobileHam {
      @include responsive(tab-port-min) {
        display: none;
      }

      @include responsive(tab-port) {
        display: flex;
      }
    }
  }
}

.showOnMobile {
  @include responsive(tab-port-min) {
    display: none;
  }

  @include responsive(tab-port) {
    display: block !important;
  }
}

.pw_input {
  position: relative;

  .eye_icon {
    position: absolute;
    right: 20px;
    transform: translateY(-50%);
    top: 50%;
    cursor: pointer;
  }
}

.nv_icon {
  display: flex;
  padding-right: 7px;

  img {
    width: 25px;
  }
}

.dropdown-toggle {
  &>span {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
}

.nav_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 55px;

  @include responsive(tab-port) {
    padding: 10px 15px;
  }

  .nav_left {
    padding-left: 0px;

    @include responsive(devicemax1052) {
      padding-left: 0;
    }

    img {
      width: 230px;

      @include responsive(phone) {
        width: 150px;
      }
    }
  }
}

.menuNav {
  position: absolute;
  z-index: 999;
  background-color: #fff;
  width: 100%;
  transition: all 1s;
  top: 0;
}

.menuNav.stickyMenu {
  background: #ffffff;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  transition: all 0.1s ease-out;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: -10px 10px 25px -30px rgba(0, 0, 0, 0.75);
}

.body_form {
  text-align: left;

  &>p {
    span {
      display: block;
    }
  }

  form {

    input,
    textarea {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #C2C2C2;
      border-radius: 0;
      padding: 15px;
    }

    button {
      width: 100%;
      background: #0EA47A 0% 0% no-repeat padding-box;
      border-radius: 4px;
      padding: 15px;
      border: none;
    }
  }
}

.str_redirect {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;

  @include responsive(devicemax1052) {
    gap: 10px !important;
    justify-content: center;
  }
}

.modal-content {
  padding: 15px;
}

.form-control {
  font-size: 17px !important;

  @include responsive(tab-port) {
    font-size: 16px !important;
  }
}

.body_modal {
  padding-top: 20px;

  form {
    input {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #C2C2C2;
      border-radius: 0;
      padding: 15px;
    }

    button {
      width: 100%;
      background: #0EA47A 0% 0% no-repeat padding-box;
      border-radius: 4px;
      padding: 15px 10px;
      border: none;
      color: #fff;
      font-size: 20px;
    }
  }

  .success_top {
    text-align: center;

    p {
      letter-spacing: 0px;
      color: #0EA47A;
    }
  }

  .success_close {
    display: flex;
    justify-content: center;
    padding-top: 10px;

    button {
      border: 1px solid #0EA47A;
      border-radius: 8px;
      font-size: 22px;
      font-weight: 800;
      padding: 10px 70px !important;
      color: #0EA47A;
      background-color: transparent;
    }
  }

  &.submitted_body_modal {
    background: #0ea47928 0% 0% no-repeat padding-box;
    padding: 15px;
    border-radius: 7px;

    span {
      padding-bottom: 15px;
      display: block;
    }
  }
}

.mod_hdr {
  border: none !important;
  padding-bottom: 0 !important;
}


.contact_detail h2 {
  font-size: 45px;
}


.contact_detail {
  padding-top: 140px;
  padding-bottom: 100px;
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;

  .cd_left {
    text-align: left;

    &>h2 {
      font-size: 56px;

      @include responsive(tab-port) {
        font-size: 38px !important;
      }
    }

    .cdl_dec {
      text-align: left;
      padding-top: 30px;

      &>span {
        padding-bottom: 20px;
        display: block;
        font-size: 20px;

        @include responsive(tab-port) {
          font-size: 16px !important;
        }
      }

      p {
        span {
          display: block;
          font-weight: 600;
          font-size: 20px;

          @include responsive(tab-port) {
            font-size: 16px !important;
          }
        }
      }
    }

    .ss_icons {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}

.pg_inside {
  padding-top: 140px;
  padding-bottom: 100px;

  &>h2 {
    font: normal normal bold 45px Proxima Nova;
    letter-spacing: 0px;
    color: #13243C;
    margin-bottom: 30px;

    @include responsive(tab-port) {
      font: normal normal bold 30px/69px Proxima Nova;
      margin-bottom: 10px;
    }
  }

  .pg_con {
    h3 {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 24px;

      @include responsive(tab-port) {
        font: normal normal bold 18px/30px Proxima Nova;
      }
    }

    p {
      margin-bottom: 20px;
      opacity: 0.93;

      @include responsive(tab-port) {
        font: normal normal 14px/25px Proxima Nova;
        margin-bottom: 20px;
      }
    }
  }
}

.body_modal {
  .c_fld {
    position: relative;
    display: flex;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #C2C2C2;

    span {
      padding: 16px 6px;
      background: #c2c2c283 0% 0% no-repeat padding-box;
    }

    input {
      border: none;
    }
  }
}

button.regBtn {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 19px;
  // font-weight: 600;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  letter-spacing: 0px;
  color: #0EA47A !important;
  padding: 17px 60px;
  border: none;
}

.rg_rt {
  padding-left: 30px;

  @include responsive(tab-port) {
    padding-left: 0;
  }
}

.light-bgcolor {
  position: relative;

  .seal_img {
    position: absolute;
    right: 35px;
    top: 40px;

    img {
      width: 185px;

      @include responsive(device1200max) {
        width: 150px;
      }
    }

    @include responsive(tab-port) {
      display: none;
    }
  }
}

.com_text {
  font-size: 26px;
  letter-spacing: 0px;
  color: #0EA47A;
  line-height: 43px;

  @include responsive(devicemax1052) {
    font-size: 24px;
    line-height: 30px;
  }
}

.ml_custom {
  margin-left: 40px;

}

.sec_testimonials {
  padding-bottom: 20px;

  .vid_carousel {
    .carousel-indicators {
      bottom: -65px;

      button {
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }
    }
  }
}

.fc_row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;

  @include responsive(tab-port) {
    flex-direction: column;
    align-items: center;
  }
}

.modal-title.h4 {
  font-size: 32px;

  @include responsive(tab-port) {
    font-size: 22px;
  }
}

.modal_close {
  cursor: pointer;
}

.modal-dialog {
  max-width: 410px;
}

.pt100 {
  padding-top: 100px;
}

.btn_border {
  border: 1px solid #B0B4BB;
  border-radius: 4px;
  color: white;

}

.banner_img {
  float: right;
}

.custom_store {
  width: 195px;
  margin-left: 0px;
  margin-right: 0px;

  @media screen and (max-width: 850px) {
    margin-left: 4px;
    margin-right: 4px;
    width: 150px;
  }
}

.wd_custom {
  width: 290px;
}

.wd_custom1 {
  width: 335px;
}


.position_djst {

  margin-top: 20px;

  @media screen and (max-width: 750px) {
    position: relative;
    top: -8px;
    margin-bottom: 30px;
  }
}

.sidemenu_wrap::before {
  width: 100%;
  height: 100%;
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  background-color: #13243cde;
  z-index: -2;
}

.react-player__preview {
  border-radius: 8px !important;
}

.ReactPlayer {
  border-radius: 0.25rem !important;
}

.videostyle {}

.videostyle video {
  border-radius: 8px !important;
}

.added_link {
  text-decoration: none;
  span, i {
    color: #0ea47a;
  }
}
.ant-alert{
  margin-top: 10px;
}