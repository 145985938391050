@import 'variables.scss';

.logo{
        max-width: 220px;
    }
.navbar-toggler-icon{
    background-image: url(./../../public/assets/images/menu.svg);
}
.custom-hed{
    .navbar-toggler{
      border: none;
    }
    .navbar-toggler:focus,
    .navbar-toggler:active,
    .navbar-toggler-icon:focus {
        outline: none;
        box-shadow: none;
    }
}
.btn-close{
    background-image: url(./../../public/assets/images/close.svg);
    width: 20px;
    height: 20px;
    background-size: 100% 100%;
    margin-right: 10px;
    margin-top: 10px;
}
.nav-link{
    font-size: 26px;
    padding: 10px;
    color: $primary-color;
    font-weight: 400;
    border-bottom:1px solid #DEDEDE ;
    background-image: url(./../../public/assets/images/arrow.svg);
    background-repeat: no-repeat;
    background-position: right;
  
}

footer{
    background: #13243C;
    padding: 3rem 0;
    ul{
        padding: 0px;
        overflow: hidden;
        margin: 0px;
        li{
            float: left;
            a{
                color: white;
                text-decoration: none;
                font-size: 1rem;
                &:hover{
                    color: white;
                }
            }
        }
    }
}