@import './scss/header.scss';
@import './scss/variables.scss';
@import './scss/themes.scss';

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('fonts/ProximaNova-Regular.woff2') format('woff2'),
      url('fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body {
  margin: 0;
  font-family: 'Proxima Nova Rg';
}

